<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="accent text-center" space="100">
      <template>
        <v-container>
          <base-section-heading
            title="Contato"
            color="white"
            text-color="white"
          />
          <v-row>
            <v-col cols="12">
              <h1>Solicite um orçamento</h1>
              <span class="text-subtitle-1"
                >Um novo conceito em gestão condominial</span
              >
              <v-row align="center" class="mt-4 spacer" no-gutters>
                <v-col cols="1">
                  <a href="https://wa.me/5534991855113">
                    <img
                      :src="require('@/assets/icons/002-phone.png')"
                      width="35px"
                    />
                  </a>
                </v-col>
                <v-col cols="11" class="text-left pl-2">
                  <span 
                    ><a style="color: white;" href="tel:034991855113">+55 (34) 9 9185-5113</a></span
                  >
                </v-col>
                <v-col cols="12" class="pa-2" />
                <v-col cols="1">
                  <img
                    :src="require('@/assets/icons/001-email.png')"
                    width="35px"
                  />
                </v-col>
                <v-col cols="11" class="text-left pl-2">
                  <span>contato@singularegestao.com.br</span>
                </v-col>
                <v-col cols="12" class="pa-2" />
                <v-col cols="1">
                  <img
                    :src="require('@/assets/icons/001-address.png')"
                    width="35px"
                  />
                </v-col>
                <v-col cols="11" class="text-left pl-2">
                  <a class="" href="https://goo.gl/maps/GzMBRHpYJXDyx4wK9">
                    <span style="color: white;"
                      ><b>Endereço</b>: R. Arthur Bernardes, 37 -
                      MartinsUberlândia - MG, 38400-368
                    </span>
                  </a>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="showForm" cols="12" md="6">
              <v-row>
                <v-col v-if="userMessage && userMessage.msg" cols="12">
                  <h1 v-if="userMessage.status == 1" class="green--text">
                    {{ userMessage.msg }}
                  </h1>
                  <h1 v-if="userMessage.status == 2" class="red--text">
                    {{ userMessage.msg }}
                  </h1>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.name"
                    :disabled="sending"
                    dense
                    label="Nome"
                    filled
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.from"
                    :disabled="sending"
                    dense
                    label="E-mail"
                    filled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="form.subject"
                    :disabled="sending"
                    dense
                    label="Assunto"
                    filled
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="form.msg"
                    :disabled="sending"
                    label="Mensagem"
                    filled
                  />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn :disabled="sending" @click="sendMail"> Enviar </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionContactUs",
  data() {
    return {
      showForm: false,
      userMessage: {
        msg: null,
        status: 0,
      },
      sending: false,
      form: {
        name: null,
        from: null,
        subject: null,
        msg: null,
      },
    };
  },
  provide: {
    heading: { align: "center" },
  },
  methods: {
    sendMail() {
      var vm = this;
      vm.sending = true;
      vm.userMessage.msg = null;
      vm.userMessage.status = 0;
      if (vm.form.name && vm.form.from && vm.form.msg) {
        return new Promise((resolve, reject) => {
          vm.axios({
            method: "POST",
            url: "https://8np8v5g84j.execute-api.us-east-1.amazonaws.com/default/singulare-send-email-ses",
            crossDomain: true,
            headers: {
              // Authorization: `${vm.$lConfig.TokenType} ${vm.$lConfig.AccessToken}`
            },
            data: vm.form,
          })
            .then((response) => {
              vm.userMessage.msg = "E-mail enviado com sucesso!!";
              vm.userMessage.status = 1;
              vm.form.msg = null;
              vm.sending = false;
              resolve(response);
            })
            .catch((error) => {
              console.log("ERROR");
              vm.userMessage.msg = "Erro ao enviar e-mail: " + error.message;
              vm.userMessage.status = 2;
              vm.sending = false;
              reject(error);
            });
        });
      } else {
        vm.userMessage.msg =
          "É necessário preencher o NOME, EMAIL e a sua MENSAGEM";
        vm.userMessage.status = 2;
        vm.sending = false;
      }
    },
  },
};
</script>
